import './ConfirmButton.css'
export default function ConfirmButton(props) {
  return (
    <div className={`button-container ${props.fullWidth ? 'full-width-container' : ''}`}>
      <button className={`confirm-button`} onClick={props.onClick}>
        {props.text} <img src="/arrow.svg" width="16" style={{ marginLeft: 10 }} alt="Arrow" />
      </button>
    </div>
  )
}
