import { useEffect, useState } from 'react'

const Tools = {
  capitalizeCase: (word) => {
    return word
      .toLowerCase()
      .split(' ')
      .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
      .join(' ')
  },

  isNextDay: (segments) => {
    let startDay = Tools.parseServerTime(segments[0].departure_time)
    let arrivalDay = Tools.parseServerTime(segments[segments.length - 1].arrival_time)
    if (startDay.getDate() !== arrivalDay.getDate()) return true
    return false
  },

  roundTo2Digits: (n) => {
    return Math.round((n + Number.EPSILON) * 100) / 100
  },

  formatNumber: (n) => {
    return new Intl.NumberFormat('de-CH').format(Tools.roundTo2Digits(n))
  },
  formatTravelDate: (d) => {
    let dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    d = d.split('T')[0]
    let incomingYear = d.split('-')[0]
    let incomingMonth = d.split('-')[1]
    let incomingDay = d.split('-')[2]
    let jsDate = new Date(
      incomingYear,
      incomingMonth - 1 /* damn you javascript */,
      incomingDay,
      0,
      0,
      0,
      0
    )
    return (
      dayOfWeek[jsDate.getDay()] +
      ', ' +
      jsDate.getDate() +
      ' ' +
      months[jsDate.getMonth()] +
      ' ' +
      jsDate.getFullYear()
    )
  },

  formatTravelTime: (d) => {
    d = d.split('T')[1]
    return d.split(':')[0] + ':' + d.split(':')[1]
  },

  parseServerTime: (d) => {
    let [date, time] = d.split('T')
    let incomingYear = date.split('-')[0]
    let incomingMonth = date.split('-')[1]
    let incomingDay = date.split('-')[2]
    let incomingHour = time.split(':')[0]
    let incomingMinute = time.split(':')[1]
    return new Date(
      incomingYear,
      incomingMonth - 1,
      incomingDay,
      incomingHour,
      incomingMinute,
      0,
      0
    )
  },

  percentageImprovement: (a, base) => {
    if (base === 0) return 0;
    return -1 * (100 - Math.round((100 * a) / base))
  },

  figureOutCarrier: (segment) => {
    return segment.operating_carrier_code ? segment.operating_carrier_code : segment.carrier_code
  },

  figureOutCabinClass: (itinerary) => {
    // Just in case we get invalid data
    if (itinerary.segments.length === 0) return "UNKNOWN";

    // On Non-Stop Flights: Easy (just use the one cabin class)
    if (itinerary.segments.length === 1) {
      return itinerary.segments[0].cabin_class;
    }
    // On Multi-Stop Flights:
    // If there's PREM ECO and ECO on a route, then we consider this a PREM ECO flight
    // If there's FIRST and BUSINESS on a route, then we just show BUSINESS (!)
    if (itinerary.segments.find((segment) => segment.cabin_class === "PREMIUM_ECONOMY") && itinerary.segments.find((segment) => segment.cabin_class === "ECONOMY")) {
      return "PREMIUM_ECONOMY";
    }
    if (itinerary.segments.find((segment) => segment.cabin_class === "FIRST") && itinerary.segments.find((segment) => segment.cabin_class === "BUSINESS")) {
      return "BUSINESS";
    }
    // If we've arrived here, it means we've got the same cabin class on all segments, so return the first one.
    return itinerary.segments[0].cabin_class;
  },

  formatCabinClass: (cabin_class) => {
    var c = cabin_class.replace("PREMIUM_ECONOMY", "Premium Eco"); // Economy and Business can be taken as-is
    return Tools.capitalizeCase(c).toUpperCase();
  },

  /* This is a helper function that tells us if the window is resizing, or, more importantly,
     if we're in a mobile-sized environment, where we should be showing a mobile-optimized components */
  useWindowSize() {
    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined })
    useEffect(() => {
      function handleResize() {
        var Android = /Android/.test(navigator.userAgent)
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        var iw = iOS || Android ? window.screen.width : window.innerWidth,
          ih = iOS || Android ? window.screen.height : window.innerHeight
        setWindowSize({
          width: iw,
          height: ih
        })
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
  },

  renderStopOvers: (segments) => {
    if (segments.length <= 1) return 'Non-Stop'
    return `${segments.length - 1} Stop${segments.length-1>1 ? "s" : ""}: ${segments
      .slice(0, segments.length - 1)
      .map((segment) => segment.destination)
      .join(', ')}`
  }
}

export default Tools
