import Tools from '../Tools'

export default function FlightRowDesktop(props) {
  const colorWrapNumber = (n, postfix = '') => {
    let isNullorNegative =
      parseInt(
        n.toString().replace('+', '').replace('-', '').replace('%', '')
      ) <= 0
    return (
      <span style={{ color: isNullorNegative ? '#DD4155' : '#80B000' }}>
        {isNullorNegative ? '-' : '+'}
        {Math.abs(parseInt(n))}
        {postfix || ''}
      </span>
    )
  }

  const colorWrapNumberInverse = (n, postfix = '') => {
    let isNullorNegative = parseInt(n) <= 0
    return (
      <span style={{ color: isNullorNegative ? '#80B000' : '#DD4155' }}>
        {isNullorNegative ? '-' : '+'}
        {Math.abs(parseInt(n))}
        {postfix || ''}
      </span>
    )
  }

  const flight = () => {
    return props.offer[props.direction]
  }

  const renderDuration = (minutes) => {
    var h = Math.floor(minutes / 60)
    var m = minutes % 60
    if (h === 0) return `${m} min`
    return `${h}h ${m} min`
  }

  const co2Savings = () => {
    return (
      -1 *
      (100 -
        Math.round(
          (100 * props.offer[props.direction].climate_impact) /
            props.habitToBeat[props.direction].climate_impact
        ))
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
      <div
        style={{ paddingLeft: 30, paddingRight: 30, fontSize: 13, width: 120 }}
      >
        {flight()
          .segments.map((segment) => {
            return `${Tools.figureOutCarrier(segment)} ${segment.flight_number}`
          })
          .join(', ')}
        <br />
        <span style={{ fontSize: 10, color: '#666' }}>
          {flight()
            .segments.map((segment) => segment.aircraft_code)
            .join(', ')}
        </span>
      </div>
      <div style={{ minWidth: 100 }}>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          {Tools.formatCabinClass(Tools.figureOutCabinClass(flight()))}
        </div>
        <div style={{ fontSize: 12 }}>{flight().aircraft_code}</div>
      </div>
      <div>
        <div
          style={{
            marginLeft: 20,
            backgroundColor: flight().priority ? '#ECEDF9' : '#fff',
            padding: '5px 10px',
            borderRadius: 4,
            fontSize: 10,
            fontWeight: 'bold',
            minWidth: 70,
            textAlign: 'center',
          }}
        >
          {flight().priority ? 'PRIORITY' : '-'}
        </div>
      </div>
      <div style={{ minWidth: 70, textAlign: 'right', marginRight: 20 }}>
        <img src='/departure.svg' width='34' alt='departure' />
      </div>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          {Tools.formatTravelTime(flight().segments[0].departure_time)}
        </div>
        <div style={{ fontSize: 12 }}>{flight().segments[0].origin}</div>
      </div>
      <div style={{ minWidth: 70, textAlign: 'right', marginRight: 20 }}>
        <img src='/arrival.svg' width='34' alt='arrival' />
      </div>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: 14, width: 75 }}>
          {Tools.formatTravelTime(
            flight().segments[flight().segments.length - 1].arrival_time
          )}
          {Tools.isNextDay(props.offer[props.direction].segments) && (
            <div
              style={{
                display: 'inline-block',
                backgroundColor: '#ddd',
                borderRadius: 4,
                padding: '2px 3px',
                fontSize: 9,
                position: 'relative',
                top: -1,
                marginLeft: 5,
              }}
            >
              +1
            </div>
          )}
        </div>
        <div style={{ fontSize: 12 }}>
          {flight().segments[flight().segments.length - 1].destination}
        </div>
      </div>
      <div style={{ minWidth: 50, textAlign: 'right', marginRight: 20 }}>
        <img src='/duration.svg' width='20' alt='duration' />
      </div>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
          {renderDuration(flight().duration)}
        </div>
        <div style={{ fontSize: 12 }}>
          {Tools.renderStopOvers(flight().segments)}
        </div>
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div style={{ textAlign: 'right', minWidth: 100 }}>
        <div style={{ color: '#333', fontSize: 18, fontWeight: 'bold' }}>
          {props.habitToBeat === props.offer && '0%'}
          {props.habitToBeat !== props.offer &&
            colorWrapNumberInverse(co2Savings(), '%')}
        </div>
        <div style={{ fontSize: 12 }}>
          {props.offer[props.direction].climate_impact} kg CO
          <sub style={{ fontSize: 8 }}>2e</sub>
        </div>
      </div>

      <div style={{ textAlign: 'right', minWidth: 100, marginRight: 22 }}>
        <div style={{ color: '#333', fontSize: 14, fontWeight: 'bold' }}>
          {props.habitToBeat === props.offer && '0'}
          {props.habitToBeat !== props.offer &&
            colorWrapNumber(`${props.offer[props.direction].fnz_points}`)}
        </div>
        <div style={{ fontSize: 12 }}>C-Points</div>
      </div>
    </div>
  )
}
