import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import App from './App'

import './index.css'
import 'typeface-lato'

/* ---- AWS Amplify for Cognito GUEST Authentication ---- */
import { Amplify } from 'aws-amplify'
Amplify.configure({
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT_ID,
  aws_cognito_verification_mechanisms: ['EMAIL'],
})
/* -------------------------------------------------------- */

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
