import Tools from '../Tools'
import ConfirmButton from './ConfirmButton'
import FlightRowDesktop from './FlightRowDesktop'
import RadioButton from './RadioButton'

export default function OfferPanelDesktop(props) {
  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 40,
        cursor: 'pointer',
        maxWidth: 1200,
      }}
      onClick={props.onSelect}
    >
      <div
        style={{
          position: 'relative',
          left: 40,
          top: 10,
          padding: '5px 15px',
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          display: 'inline-block',
          backgroundColor:
            props.section === 'habit_to_beat' ? '#333' : '#0A1EAA',
          borderRadius: 4,
          borderTopLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}
      >
        {props.topLabel}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 40,
          boxShadow: props.selected
            ? '0 16px 56px 0 rgba(10,30,170,0.24)'
            : '0 2px 8px 0 rgba(10,30,170,0.24)',
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: props.selected
              ? props.section === 'habit_to_beat'
                ? 'rgba(10,30,170,0.04)'
                : '#E0F88B'
              : 'rgba(10,30,170,0.04)',
            borderRadius: 32,
            padding: 12,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <RadioButton selected={props.selected} onClick={props.onSelect} />
          </div>
          <div style={{ fontSize: 20 }}>
            {props.section === 'habit_to_beat' && (
              <div>My current habit with zero savings</div>
            )}
            {props.section !== 'habit_to_beat' && (
              <div style={{ letterSpacing: 0.83 }}>
                Save{' '}
                <strong>
                  {props.co2Savings.roundtrip_co2_percentage}% CO
                  <sub style={{ fontSize: 12 }}>2e</sub>
                </strong>{' '}
                {props.offer.fnz_points > 0 && (
                  <span>
                    and earn{' '}
                    <strong>
                      {Tools.formatNumber(props.offer.fnz_points)} Climate
                      Points
                    </strong>
                  </span>
                )}
              </div>
            )}
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ fontSize: 14, minWidth: 100, marginRight: 10 }}>
            Round trip cost:
          </div>
          <div style={{ textAlign: 'right', minWidth: 100, marginRight: 20 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                {props.costSavings.roundtrip_cost_percentage}% &nbsp;
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  minWidth: 91,
                  textAlign: 'right',
                }}
              >
                {Tools.formatNumber(props.costSavings.roundtrip_cost)}{' '}
                {props.costSavings.currency}
              </div>
            </div>
          </div>
        </div>

        <FlightRowDesktop
          direction='outward_itinerary'
          section={props.section}
          offer={props.offer}
          habitToBeat={props.habitToBeat}
        />

        <div
          style={{
            borderBottom: '1px solid #ddd',
            marginLeft: 40,
            marginRight: 20,
          }}
        ></div>

        <FlightRowDesktop
          direction='return_itinerary'
          section={props.section}
          offer={props.offer}
          habitToBeat={props.habitToBeat}
        />

        {props.selected && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 60,
              marginTop: 20,
            }}
          >
            <div style={{ fontSize: 12, marginLeft: 40 }}>
              Currently not reserved. Only{' '}
              <span style={{ color: '#ff0000' }}>3</span> seats left. Please
              decide at your earliest convenience.
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              <ConfirmButton
                text={`Confirm ${Tools.capitalizeCase(props.topLabel)}`}
                onClick={props.onConfirm}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
