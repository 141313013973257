import './RadioButton.css';
export default function RadioButton(props) {
  if (props.selected) {
    return (
      <div className="radio-button-component" onClick={props.onClick}>
        <img src="/check.svg" alt="check" width="15"/>
      </div>
    )  
  } else {
    return (
      <div className="radio-button-component radio-button-off" onClick={props.onClick}></div>
    )  
  }
}