import './Footer.css'
export default function Footer(props) {
  return (
    <div>
      <div className='footer-container'>
        <div
          style={{
            maxWidth: 1180,
            padding: 40,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className='footer-title'>
            Learn more about
            <br />
            <strong>Climate Options.</strong>
          </div>
          <div className='footer-columns'>
            <div className='footer-columns-inner-left'>
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Best Match
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Recommended best option based on CO2 savings, price and
                convenience criteria.
              </div>
              <br />
              <br />
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Max Savings
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Option with maximum CO2 savings combined with reasonable price,
                duration, time &amp; airpoirts.
              </div>
              <br />
              <br />
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Climate Points
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Climate Points measure your savings efforts and entitle for a
                drawing during the pilot.
              </div>
            </div>

            <div style={{ flexGrow: 1 }}></div>

            <div className='footer-columns-inner-right'>
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Better Match
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Better option than habit-to-beat: reasonable CO2 savings with
                minimal trade-off of convenience.
              </div>
              <br />
              <br />
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                Habit to Beat
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Flight based on your initial inquiry or preferences: status quo
                = zero savings.
              </div>
              <br />
              <br />
              <div
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '3px',
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                More Questions?
              </div>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: 17,
                  letterSpacing: '1.5px',
                }}
              >
                Contact our specialists at
                <br />
                <a href='mailto:support@climatesmart.travel'>
                  support@climatesmart.travel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-sub-container'>
        <div className='sub-slogan'>
          <div
            style={{ fontWeight: 300, fontSize: 18, letterSpacing: '1.5px' }}
          >
            Travel more responsibly.
          </div>

          <div className='bottom-links'>
            <div className='bottom-link'>ClimPact &copy; 2022</div>
            <div className='bottom-link'>
              <a href='https://climpact.ai'>climpact.ai</a>
            </div>
            <div className='bottom-link'>
              <a href='https://climpact.ai/imprint'>Imprint</a>
            </div>
            <div className='bottom-link'>
              <a href='https://climpact.ai/privacy'>Privacy</a>
            </div>
            <div className='bottom-spacer-to-graphic'></div>
            <div className='leaf-traveler'>
              <img src='/leaf_traveler.svg' alt='Traveler' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
