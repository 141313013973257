import Tools from '../Tools'
import OfferPanelDesktop from './OfferPanelDesktop'
import OfferPanelMobile from './OfferPanelMobile'

export default function OfferPanel(props) {
  const windowSize = Tools.useWindowSize()
  const isMobile = windowSize.width && windowSize.width < 1000

  const offer = (section) => {
    switch (section || props.section) {
      case 'best_match':
        return props.offer.roundtrips.find((trip) => trip.recommendation.best_match)
      case 'better_match':
        return props.offer.roundtrips.find((trip) => trip.recommendation.better_match)
      case 'habit_to_beat':
        return props.offer.roundtrips.find((trip) => trip.recommendation.habit_to_beat)
      default:
        return []
    }
  }

  const offerType = () => {
    if (props.section === 'best_match') return 'Best Match'
    if (props.section === 'better_match') return 'Better Match'
    if (props.section === 'habit_to_beat') return 'Habit to Beat'
  }

  const co2Savings = () => {
    let habitToBeat = offer('habit_to_beat').climate_impact
    let thisOption = offer().climate_impact
    let co2ImprovementInPercent = 100 - Math.round((thisOption / habitToBeat) * 100)
    return {
      roundtrip_co2_percentage: co2ImprovementInPercent,
      roundtrip_co2_kg: thisOption
    }
  }

  const costSavings = () => {
    let habitToBeat = offer('habit_to_beat').price.value
    let thisOption = offer().price.value
    let costImprovementInPercent = Math.round((thisOption / habitToBeat) * 100) - 100
    return {
      roundtrip_cost_percentage: costImprovementInPercent,
      roundtrip_cost: thisOption,
      currency: offer().price.currency
    }
  }

  // Make sure we're not rendering if the offer is not fully prepared yet
  if (!offer() || offer().length === 0) {
    return null
  }

  // If we're on mobile, then let's use our optimized mobile version for this
  if (isMobile) {
    return (
      <OfferPanelMobile
        topLabel={offerType().toUpperCase()}
        offer={offer()}
        habitToBeat={offer('habit_to_beat')}
        section={props.section}
        selected={props.selected}
        co2Savings={co2Savings()}
        costSavings={costSavings()}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
      />
    )
  } else {
    return (
      <OfferPanelDesktop
        topLabel={offerType().toUpperCase()}
        offer={offer()}
        habitToBeat={offer('habit_to_beat')}
        section={props.section}
        selected={props.selected}
        co2Savings={co2Savings()}
        costSavings={costSavings()}
        onSelect={props.onSelect}
        onConfirm={props.onConfirm}
      />
    )
  }
}
