import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import OfferPage from './OfferPage'

function App() {
  const { hash } = useLocation()

  const [loading, setLoading] = useState(true)
  const [validOffer, setValidOffer] = useState(false)
  const [offerDetails, setOfferDetails] = useState(null)

  const loadOffer = (offerId) => {
    var offerUrl = `https://${process.env.REACT_APP_OFFERS_BUCKET}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/offers/${offerId}.json`
    fetch(offerUrl)
      .then((response) => response.json())
      .then((data) => {
        setOfferDetails(data)
        setValidOffer(true)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setValidOffer(false)
        setTimeout(() => {
          setOfferDetails(null)
        }, 500)
      })
  }

  // Load Offer Details
  useEffect(() => {
    if (hash.length > 0) {
      let cleanedVersion = hash.replace('#', '')
      loadOffer(cleanedVersion)
    } else {
      setValidOffer(false)
      setLoading(false)
    }
  }, [hash])

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: 200 }}>
        <img alt='loading' src='/loader.gif' />
      </div>
    )
  }

  if (validOffer) {
    return <OfferPage offer={offerDetails} />
  } else {
    return (
      <div style={{ textAlign: 'center', marginTop: 300 }}>
        <span style={{ fontSize: 26 }}>Sorry, your offer has expired.</span>
        <br />
        <br></br>Please reach out to your travel agent to prepare a new offer.
      </div>
    )
  }
}

export default App
